<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.packMaterialCode" class="input" placeholder="包材编码" clearable></el-input>
      <el-select v-model="queryInfo.positionCode" placeholder="库位" clearable style="margin-left: 10px">
        <el-option v-for="item in storePositionList" :key="item.code" :label="item.name" :value="item.code"></el-option>
      </el-select>
      <el-button type="primary" @click="currentChange(1)" :loading="loading" class="query-btn">查询</el-button>
    </el-row>
    <el-table :data="stockList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="positionName" label="库位名称"></el-table-column>
      <el-table-column align="center" prop="packMaterialCode" label="包材编码"></el-table-column>
      <el-table-column align="center" prop="packMaterialName" label="包材名称"></el-table-column>
      <el-table-column align="center" prop="stockAmount" label="库存"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="600px">
        <template slot-scope="scope">
          <el-select v-model="scope.row.changeType" style="width:90px;margin-right: 10px" size="mini">
            <el-option label="增加" value="add"></el-option>
            <el-option label="减少" value="reduce"></el-option>
          </el-select>
          <el-input-number v-model="scope.row.changeAmount" :min="1" :step="1" step-strictly style="width: 110px;margin-right: 10px" size="mini"></el-input-number>
          <el-button @click="changeStoreAmount(scope.row)" :loading="currentRowId===scope.row.id" size="mini">提交</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "StockManage",
  data() {
    return {
      queryInfo: {
        packMaterialCode: '',
        positionCode: '',
        pageNo: 1
      },
      currentRowId: undefined,
      loading: false,
      total: 0,
      pageSize: 0,
      stockList: [],
      storePositionList: []
    }
  },
  created() {
    this.$axios.post('storePosition/getAllStorePosition').then(response => {
      let res = response.data;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.storePositionList = res.data;
    }, error => {
      this.loading = false;
      return this.$message.error('查询库位失败，' + error.message);
    });

    this.currentChange(1)
  },
  methods: {
    search() {
      this.loading = true;
      this.$axios.post('storeAmount/queryPage', this.queryInfo).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.stockList = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    changeStoreAmount(row) {
      if (!row.changeType) {
        return this.$message.error("请选择库存变动类型")
      }
      if (!row.changeAmount) {
        return this.$message.error("请输入增减数量")
      }
      if (row.changeType == 'reduce' && row.stockAmount < row.changeAmount) {
        return this.$message.error("库存不足")
      }
      this.currentRowId = row.id
      this.$axios.post('storeAmount/adjust', {
        changeType: row.changeType,
        stockAmount: row.changeAmount,
        id: row.id
      }).then(response => {
        this.currentRowId = undefined
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.$message.success('提交成功');
        this.search();
      }, error => {
        this.currentRowId = undefined
        return this.$message.error('提交失败，' + error.message);
      });

    },
  }
}
</script>

<style scoped>
.input {
  width: 200px;
}

.query-btn {
  margin-left: 10px;
}
</style>